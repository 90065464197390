import React, { ReactElement, useState } from 'react';
import Show from './Show';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from 'url:../assets/logo.png';
import useIsInViewport from 'use-is-in-viewport';
import { useWindowSize } from '../../shared/useWindowSize';
import { useEffect } from 'react';

export default function Header(): ReactElement {
  const [width] = useWindowSize();
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && isInViewport) {
      setLoaded(true);
    }
  }, [isInViewport]);

  return (
    <div
      ref={targetRef}
      className={`flex justify-between flex-col md:block h-screen duration-700 delay-150 relative transform transition-all ease-out ${
        !loaded && !(width < 768) ? 'opacity-0 translate-y-12' : 'opacity-100 translate-y-0'
      }`}
    >
      <div className="md:w-full md:absolute py-4 md:px-4 left-0">
        <div className="md:max-w-6xl md:mx-auto md:flex md:items-center md:justify-between">
          <div className="flex items-align items-center">
            <img src={logo} className="h-5 mr-2" />
            <a href="/" className="inline-block py-2 text-white text-xl font-bold hover:text-sherpa-blue-200">
              Plockle
            </a>

            {/* <div className="inline-block cursor-pointer md:hidden">
                <div className="bg-gray-400 w-8 mb-2" style={{ height: '2px' }} />
                <div className="bg-gray-400 w-8 mb-2" style={{ height: '2px' }} />
                <div className="bg-gray-400 w-8" style={{ height: '2px' }} />
              </div> */}
          </div>
          <div className="hidden md:block">
            {/* <a href="#" className="inline-block py-1 md:py-4 text-gray-100 mr-6 font-bold">About</a> */}
            <a
              href="#about"
              className="font-bold inline-block py-1 md:py-4 text-sherpa-blue-100 hover:text-sherpa-blue-200 mr-6 hover:no-underline"
            >
              About
            </a>
            <a
              href="#tutorial"
              className="font-bold inline-block py-1 md:py-4 text-sherpa-blue-100 hover:text-sherpa-blue-200 mr-6 hover:no-underline"
            >
              Tutorial
            </a>
            <a
              href="#referral"
              className="font-bold inline-block py-1 md:py-4 text-sherpa-blue-100 hover:text-sherpa-blue-200 mr-6 hover:no-underline"
            >
              Referral
            </a>
            <a
              href="#support"
              className="font-bold  inline-block py-1 md:py-4 text-sherpa-blue-100 hover:text-sherpa-blue-200 mr-6 hover:no-underline"
            >
              Support
            </a>
            <a
              href="#contact"
              className="font-bold  inline-block py-1 md:py-4 text-sherpa-blue-100 hover:text-sherpa-blue-200 mr-6"
            >
              Contact
            </a>
            <a href="/play" className="py-3 px-8 font-bold text-white bg-emerald-500 hover:bg-emerald-600 rounded-lg shadow">
              Play
            </a>
          </div>
        </div>
      </div>
      <div className="flex md:h-screen md:items-start md:flex-col md:justify-center md:overflow-hidden md:max-w-6xl md:mx-auto">
        <div className="mb-4 md:my-0">
          <div className="md:flex md:flex-wrap md:w-full z-0">
            <div className="z-10">
              <h1 className="font-bold text-white text-8xl md:text-10xl leading-none">
                Plockle
                {/* <span className="text-sherpa-blue-400 text-4xl">.com</span> */}
              </h1>
              <h2 className="font-bold text-sherpa-blue-100 text-2xl sm:text-3xl md:text-5xl leading-tight">
                VR block puzzle game
              </h2>
              <p className="font-bold text-sherpa-blue-300 mt-1 text-xl sm:text-lg leading-tight">
                Play in virtual reality, for free, in your browser.
              </p>
              <div className="flex items-center mt-4">
                <a
                  href="/play"
                  className="inline-block py-3 px-8 font-bold text-xl text-white bg-emerald-500 hover:bg-emerald-600 rounded-lg shadow"
                >
                  Play Plockle
                </a>
                <div>
                  <span className="ml-4 text-base text-sherpa-blue-100 font-bold">Rated 4.1/5 ❤️</span>
                  <span className="ml-2 text-sm text-sherpa-blue-200">(5000+ puzzlers)</span>
                </div>
                {/* <a href="/play" className="inline-block py-3 px-8 font-bold text-xl text-white bg-emerald-500 hover:bg-emerald-600 rounded-lg shadow mt-4 ml-2">Checkout the demo</a> */}
              </div>
            </div>
          </div>
        </div>
        {/* <svg className="fill-current text-white block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fillOpacity={1} d="M0,224L1440,32L1440,320L0,320Z" />
          </svg> */}
      </div>
    </div>
  );
}
