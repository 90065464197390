import React, { ReactElement, useRef, useState } from 'react';
import { useEffect } from 'react';
import useIsInViewport from 'use-is-in-viewport';
import { useStore } from '../../store';
import { useSmoothScrollTo } from '../../shared/useSmoothScrollTo';
import { useWindowSize } from '../../shared/useWindowSize';

export default function Referral(): ReactElement {
  const [width] = useWindowSize();
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 20 });
  const user = useStore((state) => state.user);

  const [error, setError] = useState<string | null>(null);

  const [loaded, setLoaded] = useState(false);
  const [copied, setCopied] = useState(false);

  const userId = useStore((state) => state.user?.id);
  const bind = useSmoothScrollTo('#referral');

  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!loaded && isInViewport) {
      setLoaded(true);
    }
  }, [isInViewport]);

  useEffect(() => {
    if (!user.loggedInAt) {
      setError('Play the game first!');
    } else {
      setError(null);
    }
  }, [user]);

  const copyToClipboard = (e) => {
    ref.current!.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    // ref.current!.focus();
    setCopied(true);
  };

  return (
    <div className={`md:max-w-6xl md:mx-auto mt-16 md:mt-32 relative`} {...bind}>
      <div
        ref={targetRef}
        className={`duration-700 delay-150 transform transition-all ease-out ${
          !loaded && !(width < 768) ? 'opacity-0 translate-y-12' : 'opacity-100 translate-y-0'
        }`}
      >
        <h1 className="font-bold text-white text-4xl md:text-5xl leading-tight">Referral</h1>
        <div className="flex flex-wrap">
          <div className={`w-full md:pr-8 ${user.loggedInAt ? 'md:w-1/2' : ''}`}>
            <p className="font-bold text-sherpa-blue-100 text-2xl md:text-2xl leading-tight">
              You know people that have a VR headset?
            </p>
            <p className="text-sherpa-blue-200">
              I'm currently <strong>trying to get</strong> as much <strong>feedback</strong> as possible for Plockle. If you know{' '}
              <strong>anyone with a VR headset</strong>, make sure to <strong>send them your referral link</strong>.
            </p>
            <p className="text-sherpa-blue-200">
              If you <strong>invite 3</strong> or more friends who give the game a good chance, you'll{' '}
              <strong>get the final game for free</strong>. Don't worry if you don't have any friends (with vr headsets), there
              will always be a free version.
            </p>
            {!user && <p className="text-sherpa-blue-200">Play the game in this browser to get access to a referral link!</p>}
          </div>
          {user.loggedInAt && (
            <form className="w-full md:w-1/2">
              <div className="flex flex-wrap -mx-3">
                <div className="w-full px-3">
                  <label className="block tracking-wide text-white">
                    Link
                    <input
                      ref={ref}
                      className="my-2 appearance-none block w-full bg-sherpa-blue-400 text-white border border-sherpa-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-sherpa-blue-400 focus:border-emerald-500"
                      id="link"
                      type="link"
                      value={user ? `${process.env.URL}/code/${userId}` : ''}
                    />
                  </label>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={copyToClipboard}
                  className="font-bold focus:outline-none inline-block py-3 px-8 text-white bg-emerald-500 hover:bg-emerald-600 rounded-lg shadow"
                  disabled={!user.loggedInAt}
                >
                  Copy
                </button>
                {copied && <span className="ml-2">Link copied</span>}
                {error && <span className="ml-2 text-red-500">{error}</span>}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
