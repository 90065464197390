import React, { ReactElement, useState } from 'react';
import { useEffect } from 'react';
import useIsInViewport from 'use-is-in-viewport';
import { useSmoothScrollTo } from '../../shared/useSmoothScrollTo';
import { useWindowSize } from '../../shared/useWindowSize';

export default function About(): ReactElement {
  const [width] = useWindowSize();
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 20 });
  const [loaded, setLoaded] = useState(false);
  const bind = useSmoothScrollTo('#about');

  useEffect(() => {
    if (!loaded && isInViewport) {
      setLoaded(true);
    }
  }, [isInViewport]);

  return (
    <div className={`md:grid md:grid-cols-2 md:gap-6 md:max-w-6xl md:mx-auto mt-16 md:mt-0`} {...bind}>
      <div
        className={`duration-700 delay-150 relative transform transition-all ease-out ${
          !loaded && !(width < 768) ? 'opacity-0 translate-y-12' : 'opacity-100 translate-y-0'
        }`}
        ref={targetRef}
      >
        <h1 className="font-bold text-white text-4xl md:text-5xl leading-tight">About</h1>
        <p className="font-bold text-sherpa-blue-100 text-2xl md:text-2xl leading-tight">Plockle? Never heard of it</p>
        <p className="text-sherpa-blue-200">
          Plockle is a <strong>challenging and addictive block puzzle game</strong>, in <strong>virtual reality</strong>, that can
          be played in the <strong>browser</strong>. That means that <strong>no download or payment</strong> is required in order
          to play the game.
        </p>
        <p>
          At the moment, Plockle has around 40 levels which make up for around <strong>10 hours of puzzle time</strong>. This will
          keep you busy while we create more levels!
        </p>
      </div>
      <div
        className={`relative w-full mt-4 duration-700 delay-150 relative transform transition-all ease-out ${
          !loaded && !(width < 768) ? 'opacity-0 translate-y-12' : 'opacity-100 translate-y-0'
        }`}
        style={{ paddingTop: '56.25%' }}
      >
        <iframe className="absolute inset-0 w-full h-full" src="https://www.youtube-nocookie.com/embed/a15uQOTjOIs"></iframe>
      </div>
    </div>
  );
}
