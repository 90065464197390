import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCFEa3wx2pExX1LXcn8mbAPPaXTl3c5Rag',
  authDomain: 'plockle.firebaseapp.com',
  projectId: 'plockle',
  storageBucket: 'plockle.appspot.com',
  messagingSenderId: '142855309608',
  appId: '1:142855309608:web:f7d1dc9a01c5c8752a681d',
  measurementId: 'G-4XV1JM66KT',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
} else {
  firebase.app();
}

export const fb = firebase;
export const firestore = firebase.firestore();
export const auth = firebase.auth();
