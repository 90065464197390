import React, { ReactElement } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from 'url:../assets/logo.png';

export default function Footer(): ReactElement {
  return (
    <div className="text-xl flex items-center justify-between items-center py-4  mt-16 md:mt-32 md:max-w-6xl md:mx-auto">
      <div className="flex items-center">
        <img src={logo} className="h-5  mr-2" />
        <a href="/" className="inline-block py-2 text-white text-xl font-bold hover:text-sherpa-blue-200">
          Plockle
        </a>
      </div>
      <span>
        <span className="inline-block w-6 h-6 mr-2 animate-spin-slow " role="img">
          🔌
        </span>
        <a href="https://dries.io" className="text-white hover:text-sherpa-blue-200" target="_blank" rel="noopener noreferrer">
          dries.io
        </a>
      </span>
    </div>
  );
}
