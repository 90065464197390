import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useIsInViewport from 'use-is-in-viewport';
import { useSmoothScrollTo } from '../../shared/useSmoothScrollTo';
import { useWindowSize } from '../../shared/useWindowSize';

export const supportQuestions = [
  {
    title: 'How do I play Plockle?',
    answer: (
      <span>
        Go to{' '}
        <Link to="/play" className="border-none font-bold text-emerald-500 hover:text-emerald-600">
          https://plockle.com/play
        </Link>{' '}
        and click &quot;ENTER VR&quot;. Pick up your VR headset and controllers and start puzzling!
      </span>
    ),
  },
  {
    title: 'Do I need to download this game?',
    answer: (
      <span>
        Nope! It lives right in your browser. You just have to click{' '}
        <Link to="/play" className="border-none font-bold text-emerald-500 hover:text-emerald-600">
          play
        </Link>
        !
      </span>
    ),
  },
  {
    title: 'How do I play on my Quest (2)?',
    answer: (
      <span>
        Put on your VR headset and open your browser from the apps in your Quest (2). Navigate to{' '}
        <Link to="/play" className="border-none font-bold text-emerald-500 hover:text-emerald-600">
          https://plockle.com/play
        </Link>
        . Use your controllers to select &quot;ENTER VR&quot; using the trigger button. Make sure to accept the requested
        permissions in order to play the game.
      </span>
    ),
  },
  {
    title: `I'm getting "VR NOT SUPPORTED"?`,
    answer: (
      <span>
        It seems that you're using an outdated browser.{' '}
        <a
          href="https://immersive-web.github.io/webxr-samples/"
          className="border-none font-bold text-emerald-500 hover:text-emerald-600"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check if your browser has WebXR support
        </a>
        . Make sure to upgrade your browser if WebXR is not supported in your current one.
      </span>
    ),
  },
  {
    title: `I'm still getting "VR NOT SUPPORTED"...`,
    answer: (
      <span>
        Close all your browsers and make sure you&apos;re running one of the latest chrome versions. Open SteamVR and navigate to{' '}
        <Link to="/play" className="border-none font-bold text-emerald-500 hover:text-emerald-600">
          https://plockle.com/play
        </Link>
        . Click on &quot;ENTER VR&quot; in your browser and put on your VR headset. Make sure to accept the requested permissions
        in order to play the game.
      </span>
    ),
  },
  {
    title: 'Is your game still not working?',
    answer: (
      <span>
        Don&apos;t hesitate to reach out using one of the contact options below! The game is still in active development so keep
        in mind that bugs might still occur.
      </span>
    ),
  },
];

export default function Support(): ReactElement {
  const [width] = useWindowSize();
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 });
  const [loaded, setLoaded] = useState(false);

  const bind = useSmoothScrollTo('#support');

  useEffect(() => {
    if (!loaded && isInViewport) {
      setLoaded(true);
    }
  }, [isInViewport]);

  return (
    <div className={`md:max-w-6xl md:mx-auto mt-16 md:mt-32`} {...bind}>
      <div
        ref={targetRef}
        className={`duration-700 delay-150 relative transform transition-all ease-out ${
          !loaded && !(width < 768) ? 'opacity-0 translate-y-12' : 'opacity-100 translate-y-0'
        }`}
      >
        <h1 className="font-bold text-white text-4xl md:text-5xl leading-tight">Support</h1>
        <p className="font-bold text-sherpa-blue-100 text-2xl md:text-2xl leading-tight">Having trouble playing the game?</p>
        <div className="md:flex md:flex-wrap mt-4">
          {supportQuestions.map((question, index) => (
            <div className="lg:w-1/2 mt-4 md:mt-8" key={index}>
              <h2 className="font-bold text-white text-xl md:text-xl leading-tight">{question.title}</h2>
              <p className="text-sherpa-blue-200 md:text-xl md:pr-20">{question.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
