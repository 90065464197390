import * as React from "react"

interface Props {
  className: string;
  style: any;
  onClick?: any;
}

function PlayIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" {...props}>
      <path d="M37.728 328.12a14.994 14.994 0 0015.222-.4l240-149.999a15 15 0 000-25.44L52.95 2.28A15 15 0 0030 15v300a15.001 15.001 0 007.728 13.12z" />
    </svg>
  )
}

export default PlayIcon
