import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from 'url:../assets/logo.png';

export default function Loader() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <img src={logo} className="w-10 h-auto animate-roll" />
    </div>
  );
}
