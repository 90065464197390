export const demo5 = {
  build: {
    coords: [
      [0, 0, 1],
      [1, 0, 0],
      [2, 0, 0],
      [0, 0, 0],
      [1, 0, 1],
      [0, 1, 1],
      [0, 1, 0],
      [1, 1, 1],
      [0, 0, 2],
      [0, 0, 3],
      [0, 1, 3],
      [0, 1, 2],
      [0, 0, 5],
      [0, 0, 4],
      [0, 1, 4],
      [1, 0, 4],
      [1, 1, 3],
      [1, 1, 2],
      [1, 0, 2],
      [2, 1, 2],
      [1, 0, 3],
      [2, 0, 3],
      [2, 0, 2],
      [3, 0, 1],
      [2, 1, 1],
      [2, 0, 1],
      [3, 0, 2],
      [4, 0, 1],
      [3, 0, 0],
      [4, 0, 0],
      [3, 1, 0],
      [3, 1, 1],
      [4, 1, 0],
      [2, 1, 0],
      [1, 1, 0],
      [1, 2, 0],
      [0, 2, 0],
      [2, 2, 0],
      [2, 2, 1],
      [1, 2, 1],
      [3, 2, 0],
      [0, 2, 1],
      [0, 2, 2],
      [0, 2, 3],
      [1, 2, 2],
      [2, 3, 0],
      [1, 3, 0],
      [0, 3, 0],
      [1, 3, 1],
      [0, 3, 1],
      [0, 3, 2],
      [0, 4, 1],
      [0, 4, 0],
      [1, 4, 0],
      [0, 5, 0],
    ],
  },
  blocks: [
    {
      coords: [
        [0, 0, 1],
        [1, 0, 0],
        [2, 0, 0],
        [0, 0, 0],
      ],
    },
    {
      coords: [
        [1, 0, 1],
        [0, 1, 1],
        [0, 1, 0],
        [1, 1, 1],
      ],
    },
    {
      coords: [
        [0, 0, 1],
        [0, 0, 2],
        [0, 1, 2],
        [0, 1, 1],
      ],
    },
    {
      coords: [
        [0, 0, 4],
        [0, 0, 3],
        [0, 1, 3],
        [1, 0, 3],
      ],
    },
    {
      coords: [
        [1, 1, 2],
        [1, 1, 1],
        [1, 0, 1],
        [2, 1, 1],
      ],
    },
    {
      coords: [
        [1, 0, 2],
        [2, 0, 2],
        [2, 0, 1],
      ],
    },
    {
      coords: [
        [3, 0, 0],
        [2, 1, 0],
        [2, 0, 0],
        [3, 0, 1],
        [4, 0, 0],
      ],
    },
    {
      coords: [
        [3, 0, 0],
        [4, 0, 0],
        [3, 1, 0],
        [3, 1, 1],
        [4, 1, 0],
      ],
    },
    {
      coords: [
        [2, 1, 0],
        [1, 1, 0],
        [1, 2, 0],
        [0, 2, 0],
      ],
    },
    {
      coords: [
        [2, 2, 0],
        [2, 2, 1],
        [1, 2, 1],
        [3, 2, 0],
      ],
    },
    {
      coords: [
        [0, 2, 0],
        [0, 2, 1],
        [0, 2, 2],
        [1, 2, 1],
      ],
    },
    {
      coords: [
        [2, 3, 0],
        [1, 3, 0],
        [0, 3, 0],
      ],
    },
    {
      coords: [
        [1, 3, 0],
        [0, 3, 0],
        [0, 3, 1],
      ],
    },
    {
      coords: [
        [0, 4, 1],
        [0, 4, 0],
        [1, 4, 0],
        [0, 5, 0],
      ],
    },
  ],
};
