import { ImportedLevel } from '..';

export const demobtutorial: ImportedLevel = ({
  build: {
    coords: [
      [0, 0, 0],
      [0, 1, 0],
      [0, 2, 0],
      [1, 2, 0],
      [1, 1, 0],
    ],
  },
  blocks: [
    {
      coords: [
        [0, 0, 0],
        [0, 1, 0],
        [0, 2, 0],
      ],
    },
    {
      coords: [
        [1, 2, 0],
        [1, 1, 0],
      ],
    },
  ],
} as unknown) as ImportedLevel;

export const demob1: ImportedLevel = ({
  build: {
    coords: [
      [0, 0, 0],
      [0, 1, 0],
      [0, 1, 1],
      [0, 0, 1],
      [0, 0, 2],
      [0, 1, 2],
      [0, 0, 3],
      [0, 1, 3],
      [0, 1, 4],
      [0, 0, 4],
    ],
  },
  blocks: [
    {
      coords: [
        [0, 0, 0],
        [0, 1, 0],
        [0, 1, 1],
      ],
    },
    {
      coords: [
        [0, 0, 1],
        [0, 0, 2],
        [0, 1, 2],
        [0, 0, 3],
      ],
    },
    {
      coords: [
        [0, 1, 3],
        [0, 1, 4],
        [0, 0, 4],
      ],
    },
  ],
} as unknown) as ImportedLevel; // 2 x 5

export const demob2: ImportedLevel = ({
  build: {
    coords: [
      [0, 0, 0],
      [1, 0, 0],
      [1, 1, 0],
      [2, 1, 0],
      [2, 0, 0],
      [3, 0, 0],
      [4, 0, 0],
      [4, 1, 0],
      [0, 1, 0],
      [0, 2, 0],
      [1, 2, 0],
      [2, 2, 0],
      [3, 1, 0],
      [3, 2, 0],
      [4, 2, 0],
      [5, 2, 0],
      [5, 1, 0],
      [5, 0, 0],
    ],
  },
  blocks: [
    {
      coords: [
        [0, 0, 0],
        [1, 0, 0],
        [1, 1, 0],
        [2, 1, 0],
      ],
    },
    {
      coords: [
        [2, 0, 0],
        [3, 0, 0],
        [4, 0, 0],
        [4, 1, 0],
      ],
    },
    {
      coords: [
        [0, 1, 0],
        [0, 2, 0],
        [1, 2, 0],
      ],
    },
    {
      coords: [
        [2, 2, 0],
        [3, 1, 0],
        [3, 2, 0],
      ],
    },
    {
      coords: [
        [4, 2, 0],
        [5, 2, 0],
        [5, 1, 0],
        [5, 0, 0],
      ],
    },
  ],
} as unknown) as ImportedLevel; // 3 x 6

export const demob3: ImportedLevel = ({
  build: {
    coords: [
      [0, 0, 0],
      [1, 0, 0],
      [1, 1, 0],
      [1, 1, 1],
      [0, 1, 0],
      [0, 0, 1],
      [0, 1, 1],
      [1, 0, 1],
      [2, 1, 0],
      [2, 1, 1],
      [2, 0, 0],
      [2, 0, 1],
    ],
  },
  blocks: [
    {
      coords: [
        [0, 0, 0],
        [1, 0, 0],
        [1, 1, 0],
        [1, 1, 1],
      ],
    },
    {
      coords: [
        [0, 1, 0],
        [0, 0, 1],
        [0, 1, 1],
        [1, 0, 1],
      ],
    },
    {
      coords: [
        [2, 1, 0],
        [2, 1, 1],
        [2, 0, 0],
        [2, 0, 1],
      ],
    },
  ],
} as unknown) as ImportedLevel; // 2x2x3

export const demob4: ImportedLevel = ({
  build: {
    coords: [
      [0, 0, 0],
      [0, 0, 1],
      [0, 1, 0],
      [0, 1, 1],
      [1, 1, 1],
      [2, 1, 1],
      [1, 0, 1],
      [2, 0, 1],
      [2, 0, 0],
      [2, 1, 0],
      [1, 1, 0],
      [1, 1, -1],
      [1, 0, 0],
      [2, 1, -1],
      [2, 0, -1],
      [1, 0, -1],
      [0, 1, -1],
      [0, 0, -1],
    ],
  },
  blocks: [
    {
      coords: [
        [0, 0, 0],
        [0, 0, 1],
        [0, 1, 0],
      ],
    },
    {
      coords: [
        [0, 1, 1],
        [1, 1, 1],
        [2, 1, 1],
        [1, 0, 1],
      ],
    },
    {
      coords: [
        [2, 0, 1],
        [2, 0, 0],
        [2, 1, 0],
      ],
    },
    {
      coords: [
        [1, 1, 0],
        [1, 1, -1],
        [1, 0, 0],
      ],
    },
    {
      coords: [
        [2, 1, -1],
        [2, 0, -1],
        [1, 0, -1],
        [0, 1, -1],
        [0, 0, -1],
      ],
    },
  ],
} as unknown) as ImportedLevel; /// 3 x 3 x 2
