
const blueprint: Array<[x: number, y: number, z: number]> = [
  [0, 0, 0],
  [1, 0, 0],
  [2, 0, 0],
  [0, 1, 0],
  [1, 1, 0],
  [2, 1, 0],
  [0, 2, 0],
  [1, 2, 0],
  [2, 2, 0],
  [0, 0, 1],
  [1, 0, 1],
  [2, 0, 1],
  [0, 1, 1],
  [1, 1, 1],
  [2, 1, 1],
  [0, 2, 1],
  [1, 2, 1],
  [2, 2, 1],
  [0, 0, 2],
  [1, 0, 2],
  [2, 0, 2],
  [0, 1, 2],
  [1, 1, 2],
  [2, 1, 2],
  [0, 2, 2],
  [1, 2, 2],
  [2, 2, 2],
];

const block1 = [
  // [0, 1, 0],
  [0, 0, 1],
  [0, 1, 1],
  [1, 1, 1],
  [1, 2, 1],
  // [0, 1, 2]
];

const block2 = [
  [0, 0, 0],
  [1, 0, 0],
  [2, 0, 0],
  [0, 1, 0],
];

const block3 = [
  [0, 0, 0],
  [1, 0, 0],
  [2, 0, 0],
  [1, 1, 0],
];

// this block twice
const block4 = [
  [0, 0, 0],
  [1, 0, 0],
  [1, 1, 0],
  [1, 1, 1],
  [1, 1, 2],
];

const block5 = [
  [0, 0, 0],
  [1, 0, 0],
  [1, 1, 0],
  [0, 1, 0],
];

const block6 = [
  [0, 0, 0],
  [0, 1, 0],
  [1, 1, 0],
];

const blockBlueprints = [block1, block2, block6, block3, block4, block5, block6];

export const level1 = {
  build: { coords: blueprint },
  blocks: blockBlueprints.map((block) => ({ coords: block })),
}