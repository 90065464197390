import * as React from "react"

interface Props {
  className: string;
}

function MouseIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={925.333}
      height={1306.667}
      viewBox="0 0 694 980"
      {...props}
    >
      <path 
        className="stroke-current text-icon"
        fill="white"
        strokeWidth={7}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.6 1.5c-2.2.8-5.5 2.9-7.4 4.8L2.7 9.8l-.8 19.3C1 52 .9 905.3 1.8 943.8l.7 26.3 4.1 3.9c6.1 5.7 15.3 7.6 21.4 4.5 4-2.1 54.5-55.7 193.5-205.5l71.9-77.5 183.6-.6c189.9-.6 200.1-.8 208-4.7 4.7-2.3 8-8.9 8-15.8 0-4.9-.4-5.7-5.2-11.6-11.3-13.6-53.4-56.3-236.3-239.6C251.5 222.7 58 30.3 39.5 13.6 23.6-.7 22-1.5 13.6 1.5z" />
    </svg>
  )
}

export default MouseIcon
