import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { fb, firestore } from '../../firebase';
import { useStore } from '../../store';
import Loader from './Loader';


export default function ReferralRedirect() {
  const {code} = useParams<{code: string}>();
  const history = useHistory();

  const set = useStore(state => state.set);
  const userId = useStore(state => state.user?.id);

  useEffect(() => {
    // check if user has referral code
    // if not
    // set in user self
    // set in referral document
    // increment

    const checkAndSetRefferalCode =  async () => {
      const docRef = firestore.collection("players").doc(userId);
      const doc = await docRef.get();
  
      if (doc.exists) {
        const data = doc.data();
        
        if (!data!.code) {
          await docRef.update({
            code
          });

          // check if code exists in referral document
          const codeRef = firestore.collection("codes").doc(code);
          const codeDoc = await codeRef.get();

          if (codeDoc.exists) {
            // increment
            await codeRef.update({
              usages: fb.firestore.FieldValue.increment(1),
              lastUsed: new Date(),
            });
          } else {
            // add 
            await codeRef.set({
              code,
              usages: 1,
              lastUsed: new Date(),
            });
          }
        }
      }

      history.push('/');
    }

    if (userId) {
      checkAndSetRefferalCode();
    }
  }, [userId]);

  return (<Loader />);
}
