import React, { ReactElement, useRef, useState } from 'react';
import { useEffect } from 'react';
import useIsInViewport from 'use-is-in-viewport';
import { useSmoothScrollTo } from '../../shared/useSmoothScrollTo';
import { useWindowSize } from '../../shared/useWindowSize';

import tutorial1 from 'url:../assets/plockletutorial1.jpg';
import tutorial2 from 'url:../assets/plockletutorial2.jpg';

import demotutorial1 from 'url:../assets/plockledemostutorials1.webm';
import demotutorial2 from 'url:../assets/plockledemostutorial2.webm';

import playingDemo from 'url:../assets/plockledemos.webm';
import tutorial3 from 'url:../assets/plockledemo1.jpg';

import PlayIcon from './icons/PlayIcon';

const tutorials = [
  {
    title: 'Moving a block',
    text: 'Grab a block by using the trigger button on your controller. You can use both controllers at the same time.',
    image: tutorial1,
    video: demotutorial1,
  },
  {
    title: 'Moving the puzzle',
    text: 'Hover your controllers around the puzzle. Once the outlines show up, grab the puzzle by using the trigger button on your controller.',
    image: tutorial2,
    video: demotutorial2,
  },
  {
    title: 'The goal',
    text: 'Complete the puzzle by combining all the blocks together. Make sure to do a victory dance once you finish the puzzle!',
    image: tutorial3,
    video: playingDemo,
  },
];

export default function Tutorial(): ReactElement {
  const [width] = useWindowSize();
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 20 });
  const [loaded, setLoaded] = useState(false);
  const bind = useSmoothScrollTo('#tutorial');

  const refs = tutorials.map((_) => useRef(null));
  const [playing, setPlaying] = useState(tutorials.map((_) => false));

  useEffect(() => {
    if (!loaded && isInViewport) {
      setLoaded(true);
    }
  }, [isInViewport]);

  return (
    <div className={`md:max-w-6xl md:mx-auto mt-16 md:mt-32`} {...bind}>
      <div
        className={`duration-700 delay-150 relative transform transition-all ease-out ${
          !loaded && !(width < 768) ? 'opacity-0 translate-y-12' : 'opacity-100 translate-y-0'
        }`}
        ref={targetRef}
      >
        <h1 className="font-bold text-white text-4xl md:text-5xl leading-tight">Tutorial</h1>
        <p className="font-bold text-sherpa-blue-100 text-2xl md:text-2xl leading-tight">How do I play?</p>
        <div className="grid grid-cols-1 md:grid md:grid-cols-3 gap-6 mt-4">
          {tutorials.map(({ title, text, image, video }, index) => (
            <div className="bg-emerald-500 rounded-lg overflow-hidden">
              <div
                className="relative flex"
                onClick={() => {
                  if (playing[index]) {
                    refs[index].current?.pause();
                  } else {
                    refs[index].current?.play();
                  }
                  setPlaying((state) => {
                    state[index] = !state[index];
                    return [...state];
                  });
                }}
              >
                <video
                  ref={refs[index]}
                  src={video}
                  loop
                  controls={false}
                  muted
                  className="object-cover w-full h-auto"
                  style={{ backgroundImage: image }}
                />
                {!playing[index] && <img src={image} className="absolute object-cover w-full h-auto" />}
                {!playing[index] && (
                  <div className="absolute flex items-center justify-center w-full h-full">
                    <PlayIcon className="w-10 h-10 cursor-pointer" style={{ fill: 'white' }} />
                  </div>
                )}
              </div>
              <div className="p-4">
                <h1 className="flex-auto text-2xl font-bold text-white">{title}</h1>
                <p className="text-xl text-white">{text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
