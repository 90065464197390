export const testlevel = { build: { coords: [[0, 0, 0]] }, blocks: [{ coords: [[0, 0, 0]] }] };

export const testLevel1 = {
  build: { coords: [] },
  blocks: [
    {
      coords: [
        [0, 0, 0],
        [1, 0, 0],
        [1, 0, -1],
      ],
      color: '#77e5d6',
    },
    {
      coords: [
        [1, 1, 0],
        [0, 1, 0],
        [0, 0, -1],
        [0, 1, -1],
      ],
      color: '#003559',
    },
    {
      coords: [
        [1, 1, -1],
        [0, 0, -2],
        [1, 0, -2],
        [0, 1, -2],
        [0, 0, -3],
        [1, 0, -3],
      ],
      color: '#20db52',
    },
    {
      coords: [
        [1, 1, -2],
        [2, 0, -2],
        [2, 0, -1],
        [2, 0, 0],
        [2, 1, -1],
        [3, 1, -1],
        [4, 1, -1],
        [2, 1, 0],
        [2, 1, -2],
      ],
      color: '#ffddcc',
    },
    {
      coords: [
        [0, 1, -3],
        [1, 1, -3],
        [1, 2, -3],
        [1, 2, -2],
        [2, 2, -2],
        [0, 2, -1],
        [3, 2, -2],
        [0, 2, 0],
      ],
      color: '#f9f195',
    },
    {
      coords: [
        [2, 2, 0],
        [2, 2, -1],
        [2, 3, -1],
        [2, 3, 0],
        [1, 2, -1],
        [3, 2, -1],
      ],
      color: '#d6c61b',
    },
    {
      coords: [
        [3, 3, -1],
        [3, 3, -2],
        [2, 0, -3],
        [3, 4, -2],
        [2, 3, -2],
        [2, 2, -3],
      ],
      color: '#fce8c7',
    },
    {
      coords: [
        [3, 3, 0],
        [1, 2, 0],
        [4, 3, 0],
        [3, 2, 0],
        [4, 4, 0],
        [3, 1, 0],
      ],
      color: '#ffdabf',
    },
    {
      coords: [
        [3, 5, -2],
        [2, 4, -1],
        [1, 3, 0],
        [4, 5, -2],
        [3, 4, -1],
        [4, 5, -3],
      ],
      color: '#f4a85d',
    },
    {
      coords: [
        [1, 3, -1],
        [1, 3, -2],
        [1, 4, -1],
      ],
      color: '#a2bcef',
    },
    {
      coords: [
        [-1, 2, -1],
        [-2, 2, -1],
        [-1, 1, -1],
        [-1, 3, -1],
      ],
      color: '#0a1063',
    },
    {
      coords: [
        [-1, 2, 0],
        [-2, 2, 0],
        [-2, 2, 1],
      ],
      color: '#dca8f4',
    },
    {
      coords: [
        [-3, 2, 0],
        [-1, 1, 0],
        [-1, 0, -1],
        [-3, 2, -1],
        [-1, 0, -3],
        [-3, 3, -1],
        [-1, 1, -2],
        [-3, 3, 0],
        [-2, 3, -1],
        [-1, 2, -2],
      ],
      color: '#e2aaef',
    },
    {
      coords: [
        [-4, 3, -1],
        [-3, 3, -2],
        [-4, 3, -2],
        [-4, 2, -1],
        [-3, 2, 1],
        [-4, 4, -2],
      ],
      color: '#996bf4',
    },
    {
      coords: [
        [-2, 3, -2],
        [-2, 3, -3],
        [-2, 2, -2],
      ],
      color: '#01b7c4',
    },
    { coords: [[-2, 4, -3]], color: '#9656f7' },
    {
      coords: [
        [-2, 5, -3],
        [-2, 4, -2],
      ],
      color: '#efb497',
    },
    { coords: [[-2, 6, -3]], color: '#0d9111' },
    { coords: [[0, -1, -1]], color: '#c7e843' },
    { coords: [[0, -2, -1]], color: '#67db4a' },
    { coords: [[0, -3, -1]], color: '#9de847' },
    { coords: [[0, -4, -1]], color: '#ccb014' },
    { coords: [[0, -5, -1]], color: '#2c78ea' },
    {
      coords: [
        [0, -6, -1],
        [1, -1, -1],
        [2, 5, -2],
      ],
      color: '#e532a0',
    },
    {
      coords: [
        [0, -1, 0],
        [0, -2, 0],
        [0, -3, 0],
      ],
      color: '#57b4cc',
    },
    {
      coords: [
        [0, -4, 0],
        [0, -5, 0],
        [0, -6, 0],
      ],
      color: '#6ed153',
    },
    {
      coords: [
        [0, -1, 1],
        [0, -2, 1],
        [0, -3, 1],
        [0, -4, 1],
        [0, -5, 1],
        [0, -5, 2],
        [0, -6, 1],
      ],
      color: '#9145b7',
    },
  ],
};

export const testLevel2 = {
  build: {
    coords: [
      [0, 0, 0],
      [1, 0, 0],
      [1, 0, -1],
      [1, 1, 0],
      [0, 1, 0],
      [0, 0, -1],
      [0, 1, -1],
      [1, 1, -1],
      [0, 0, -2],
      [1, 0, -2],
      [0, 1, -2],
      [0, 0, -3],
      [1, 0, -3],
      [1, 1, -2],
      [2, 0, -2],
      [2, 0, -1],
      [2, 0, 0],
      [2, 1, -1],
      [3, 1, -1],
      [4, 1, -1],
      [2, 1, 0],
      [2, 1, -2],
      [0, 1, -3],
      [1, 1, -3],
      [1, 2, -3],
      [1, 2, -2],
      [2, 2, -2],
      [0, 2, -1],
      [3, 2, -2],
      [0, 2, 0],
      [2, 2, 0],
      [2, 2, -1],
      [2, 3, -1],
      [2, 3, 0],
      [1, 2, -1],
      [3, 2, -1],
      [3, 3, -1],
      [3, 3, -2],
      [2, 0, -3],
      [3, 4, -2],
      [2, 3, -2],
      [2, 2, -3],
      [3, 3, 0],
      [1, 2, 0],
      [4, 3, 0],
      [3, 2, 0],
      [4, 4, 0],
      [3, 1, 0],
      [3, 5, -2],
      [2, 4, -1],
      [1, 3, 0],
      [4, 5, -2],
      [3, 4, -1],
      [4, 5, -3],
      [1, 3, -1],
      [1, 3, -2],
      [1, 4, -1],
      [-1, 2, -1],
      [-2, 2, -1],
      [-1, 1, -1],
      [-1, 3, -1],
      [-1, 2, 0],
      [-2, 2, 0],
      [-2, 2, 1],
      [-3, 2, 0],
      [-1, 1, 0],
      [-1, 0, -1],
      [-3, 2, -1],
      [-1, 0, -3],
      [-3, 3, -1],
      [-1, 1, -2],
      [-3, 3, 0],
      [-2, 3, -1],
      [-1, 2, -2],
      [-4, 3, -1],
      [-3, 3, -2],
      [-4, 3, -2],
      [-4, 2, -1],
      [-3, 2, 1],
      [-4, 4, -2],
      [-2, 3, -2],
      [-2, 3, -3],
      [-2, 2, -2],
      [-2, 4, -3],
      [-2, 5, -3],
      [-2, 4, -2],
      [-2, 6, -3],
      [0, -1, -1],
      [0, -2, -1],
      [0, -3, -1],
      [0, -4, -1],
      [0, -5, -1],
      [0, -6, -1],
      [1, -1, -1],
      [2, 5, -2],
      [0, -1, 0],
      [0, -2, 0],
      [0, -3, 0],
      [0, -4, 0],
      [0, -5, 0],
      [0, -6, 0],
      [0, -1, 1],
      [0, -2, 1],
      [0, -3, 1],
      [0, -4, 1],
      [0, -5, 1],
      [0, -5, 2],
      [0, -6, 1],
    ],
  },
  blocks: [],
};
