import produce, { setAutoFreeze } from 'immer';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import colors from 'nice-color-palettes';
import { MutableRefObject, RefObject, useRef } from 'react';
import {
  Box3,
  BoxBufferGeometry,
  BoxGeometry,
  BufferGeometry,
  Camera,
  Color,
  Euler,
  Group,
  Mesh,
  Object3D,
  Vector3,
  XRHandedness,
} from 'three';
import { OBB } from 'three/examples/jsm/math/OBB';
// TODO: fix when https://github.com/mrdoob/three.js/issues/22553 is fixed so we can upgrade to 0.132+
// import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import createStore, { State as ZustandState, StateCreator } from 'zustand';

import { fb, firestore } from './firebase';
import { levels } from './game/levels';
import { HandModel } from './game/xr/HandModel';

const immer = <T extends ZustandState>(config: StateCreator<T, (fn: (state: T) => void) => void>): StateCreator<T> => (
  set,
  get,
  api
) => config((fn) => set(produce(fn) as (state: T) => T), get, api);

setAutoFreeze(false);

export type Dimensions = [x: number, y: number, z: number];
export type Coords = Array<Vector3>;

export type Settings = {
  isCorrectVersion: boolean;
  isBackgroundTransparent: boolean;
  shouldShowSettings: boolean;
  cinematicMode: boolean;
  settingPlayPosition: boolean;
  shouldShowRecap: boolean;
  isPresenting: boolean;
  isHandTracking: boolean;
  shouldShowHint: boolean;
  playPosition: Vector3;
  playDistance?: MutableRefObject<number>;
  resetPlayPosition: (cam: Camera, playPosition?: Vector3, playDistance?: number) => void;
  randomLevels: string[];
  dailyLevel?: string;
  blockScale: number;
  lockingDistance: number;
  build: {
    innerOffset: number;
    outerOffset: number;
  };
  controllers: {
    halfSize: Vector3;
    grabbable: boolean;
    showRays: boolean;
    visible: boolean;
    pointers: {
      [key in XRHandedness]: Pointer;
    };
  };
};

export type Pointer = {
  model?: MutableRefObject<HandModel | undefined>;
  ref?: MutableRefObject<Mesh | undefined>;
  obb?: MutableRefObject<OBB | undefined>;
  bb?: MutableRefObject<Box3 | undefined>;
  interacting?: MutableRefObject<Object3D | Group | undefined>;
};

export type Block = {
  coords: Coords;
  locked?: Coords;
  blocks: Mesh[];
  // mesh: Mesh;
  // geometry: Geometry;
  dimensions: Vector3;
  ref?: MutableRefObject<Group | Mesh | undefined>;
  // initial properties
  rotation?: Euler;
  position?: Vector3;
  color: Color | string;
};

export type Build = {
  coords: Coords;
  geometry: BufferGeometry;
  dimensions: Vector3;
  ref?: MutableRefObject<Group | undefined>;
  // initial properties
  rotation: Euler;
  position: Vector3;
  // local coord bounds
  bounds?: {
    min: Vector3;
    max: Vector3;
  };
};

export type Level = {
  id?: number | string;
  loaded: boolean;
  finishedAt?: Date;
  startedAt?: Date;
  build?: Build;
  name?: string;
  hint?: string;
  blocks?: Array<Block>;
  initAnimated: boolean;
  reset: () => void;
  finish: () => void;
  quit: () => void;
  init: (id: number | string, initAnimated?: boolean, showHint?: boolean) => void;
  locked: Coords;
  recalculateLocked: () => void;
};

export type Levels = {
  [key: string]: {
    id: string;
    finishedAt?: Date;
    averageTime?: number;
    bestTime?: number;
    totalTime?: number;
    blueprint?: string;
    name?: string;
    hint?: string;
    build: {
      coords: Dimensions[];
    };
    blocks: [
      {
        coords: Dimensions[];
      }
    ];
  };
};

export type Progress = {
  id: string;
  duration: number;
  session: number;
  finishedAt?: Date;
  quitAt?: Date;
};

export type User = {
  id: string | undefined;
  loggedInAt: Date | undefined;
  sessions: number;
  code?: string;
  progress: Progress[];
  rating?: number;
  rate: (rating: number) => void;
};

export type State = {
  settings: Settings;
  user?: User;
  levels: Levels;
  level: Level;
  set: (fn: (state: State) => void | State) => void;
};

export const useStore = createStore<State>(
  immer((set, get, api) => {
    const blockScale = 0.05;

    return {
      settings: {
        isPresenting: false,
        isHandTracking: false,
        isCorrectVersion: true,
        shouldShowSettings: false,
        cinematicMode: false,
        isBackgroundTransparent: false,
        settingPlayPosition: false,
        shouldShowHint: false,
        dailyLevel: 'daily2604',
        randomLevels: [],
        shouldShowRecap: false,
        playPosition: new Vector3(0, 0, 0),
        playDistance: null,
        resetPlayPosition: (cam: Camera, playPosition, playDistance = get().settings.playDistance?.current || 0.6) => {
          const direction = cam.getWorldDirection(new Vector3());
          const position = cam.getWorldPosition(new Vector3());

          // if (!playDistance) {
          //   playDistance = get().settings.playDistance.current;
          // }
          if (!playPosition) {
            playPosition = new Vector3(direction.x, direction.y, direction.z)
              .normalize()
              .multiply(new Vector3(playDistance, playDistance, playDistance))
              .add(position);
          }

          get().set((state) => {
            state.settings.playPosition = playPosition;
            if (state.settings.playDistance?.current) {
              state.settings.playDistance.current = playPosition.distanceTo(cam.position);
            }
            return state;
          });
        },
        blockScale: blockScale,
        lockingDistance: blockScale / 4,
        build: {
          innerOffset: 2.5,
          outerOffset: 4,
        },
        controllers: {
          halfSize: new Vector3(0.25, 0.25, 0.5),
          grabbable: false,
          showRays: false,
          visible: true,
          pointers: {
            left: {
              ref: null,
              obb: null,
              bb: null,
              interacting: null,
            },
            right: {
              ref: null,
              obb: null,
              bb: null,
              interacting: null,
            },
            none: null,
          },
        },
      },
      levels: Object.keys(levels).reduce((object: Levels, key: string) => {
        object[key] = {
          id: key,
          averageTime: 0,
          bestTime: 0,
          totalTime: 0,
          finishedAt: undefined,
          ...levels[key],
        };
        return object;
      }, {}),
      user: {
        id: undefined,
        loggedInAt: undefined,
        sessions: 0,
        progress: [],
        rate: async (rating) => {
          // also set this so we can if the user has already rated
          set((state) => {
            state.user.rating = rating;
          });
          if (get().user.id) {
            const docRef = firestore.collection('players').doc(get().user!.id);
            await docRef.update({
              rating,
            });
          }
        },
      },
      level: {
        id: undefined,
        loaded: false,
        build: undefined,
        blocks: [],
        startedAt: undefined,
        finishedAt: undefined,
        initAnimated: false,
        init: (id, initAnimated = false, showHint = false) => {
          set((state) => {
            state.level.loaded = false;
            return state;
          });
          const { build: buildBlueprint, blocks: blockBlueprints, hint, name } = get().levels[id];

          const maxBlocksOffset = buildBlueprint.coords.reduce((offset, coord) => {
            offset.x < coord[0] && offset.setX(coord[0]);
            offset.y < coord[1] && offset.setY(coord[1]);
            offset.z < coord[2] && offset.setZ(coord[2]);

            return offset;
          }, new Vector3().fromArray(buildBlueprint.coords[0] || new Vector3().toArray()));

          const minBlocksOffset = buildBlueprint.coords.reduce((offset, coord) => {
            offset.x > coord[0] && offset.setX(coord[0]);
            offset.y > coord[1] && offset.setY(coord[1]);
            offset.z > coord[2] && offset.setZ(coord[2]);

            return offset;
          }, new Vector3().fromArray(buildBlueprint.coords[0] || new Vector3().toArray()));

          // const buildDimensions = new Vector3(
          //   maxBlocksOffset.x - minBlocksOffset.x + 1,
          //   maxBlocksOffset.y - minBlocksOffset.y + 1,
          //   maxBlocksOffset.z - minBlocksOffset.z + 1
          // );
          const buildDimensions = new Vector3(
            maxBlocksOffset.x - minBlocksOffset.x,
            maxBlocksOffset.y - minBlocksOffset.y,
            maxBlocksOffset.z - minBlocksOffset.z
          );

          const buildMeshes: Mesh[] = buildBlueprint.coords.map(([x, y, z]: Dimensions, index) => {
            const mesh = new Mesh(new BoxBufferGeometry(blockScale, blockScale, blockScale));

            // const position = new Vector3(x, y, z)
            //   .sub(buildDimensions.clone().divideScalar(2).add(minBlocksOffset))
            //   .multiplyScalar(blockScale)
            //   .addScalar(blockScale / 2);

            // // we had weird rounding issues. this is a hack to fix it
            // // 0.000000000001 => 0
            // // take into account that blockscale is now 0.5, if we divide by 2 0.025 => we need 3 digits
            // const roundedPosition = position.toArray().map((v) => parseFloat(v.toFixed(3)));

            // mesh.position.copy(new Vector3().fromArray(roundedPosition));

            mesh.position.set(x * blockScale, y * blockScale, z * blockScale);

            return mesh;
          });

          const blocks: Block[] = blockBlueprints
            .sort(() => {
              const r = Math.random() - 0.5;
              return r;
            })
            .map(({ coords, color }: { coords: Array<Dimensions>; color?: Color }, index: number) => {
              const maxBlockOffset = coords.reduce((offset, [x, y, z]) => {
                x > offset.x && offset.setX(x);
                y > offset.y && offset.setY(y);
                z > offset.z && offset.setZ(z);

                return offset;
              }, new Vector3().fromArray(coords[0]));

              const minBlockOffset = coords.reduce((offset, [x, y, z]) => {
                x < offset.x && offset.setX(x);
                y < offset.y && offset.setY(y);
                z < offset.z && offset.setZ(z);

                return offset;
              }, new Vector3().fromArray(coords[0]));

              // const blockDimensions = new Vector3(
              //   maxBlockOffset.x - minBlockOffset.x + 1,
              //   maxBlockOffset.y - minBlockOffset.y + 1,
              //   maxBlockOffset.z - minBlockOffset.z + 1
              // );
              const blockDimensions = new Vector3(
                maxBlockOffset.x - minBlockOffset.x,
                maxBlockOffset.y - minBlockOffset.y,
                maxBlockOffset.z - minBlockOffset.z
              );

              const vectorCoords = coords.map((coord: Dimensions) => new Vector3().fromArray(coord));
              const blocks = coords.map(([x, y, z]: Dimensions) => {
                const geometry = new BoxGeometry(blockScale, blockScale, blockScale);
                // removeFacesForAdjoiningBlocks(geometry, new Vector3(x, y, z), vectorCoords);

                const mesh = new Mesh(geometry);

                mesh.position
                  .fromArray([x, y, z])
                  .sub(blockDimensions.clone().divideScalar(2).add(minBlockOffset))
                  .multiplyScalar(blockScale);

                // const position = new Vector3(x, y, z)
                //   .sub(blockDimensions.clone().divideScalar(2).add(minBlockOffset))
                //   .multiplyScalar(blockScale)
                //   .addScalar(blockScale / 2);

                // // we had weird rounding issues. this is a hack to fix it
                // // 0.000000000001 => 0
                // // take into account that blockscale is now 0.5, if we divide by 2 0.025 => we need 3 digits
                // const roundedPosition = position.toArray().map((v) => parseFloat(v.toFixed(3)));

                // mesh.position.copy(new Vector3().fromArray(roundedPosition));

                // mesh.position.set(x * blockScale, y * blockScale, z * blockScale);

                return mesh;
              });

              return {
                coords: vectorCoords,
                blocks,
                locked: [],
                // we want to not have 0's as dimensions
                dimensions: blockDimensions,
                color: color ?? colors[84][Math.floor(Math.random() * colors[84].length)],
                position: new Vector3(
                  Math.cos(
                    ((Math.PI * 2) / blockBlueprints.length) * index + (Math.PI * 2) / blockBlueprints.length / 2 - Math.PI / 2
                  ) *
                    (Math.max(...buildDimensions.toArray()) / 2 + get().settings.build.outerOffset + 1) *
                    blockScale,
                  Math.sin(
                    ((Math.PI * 2) / blockBlueprints.length) * index + (Math.PI * 2) / blockBlueprints.length / 2 - Math.PI / 2
                  ) *
                    (Math.max(...buildDimensions.toArray()) / 2 + get().settings.build.outerOffset + 1) *
                    blockScale,
                  0
                ),
                rotation: new Euler().setFromVector3(new Vector3().random()),
              };
            });
          const first = buildMeshes[0]?.geometry || new BoxGeometry();
          const [x, y, z] = buildMeshes[0]?.position.toArray() || [0, 0, 0];
          first.translate(x, y, z);

          const buildGeometry = buildMeshes.slice(1).reduce((geometry: BufferGeometry, mesh: Mesh) => {
            const toMerge = mesh.geometry;
            toMerge.translate(...mesh.position.toArray());
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const geom = BufferGeometryUtils.mergeBufferGeometries([toMerge, geometry]);
            return geom;
          }, first);

          const build: Build = {
            coords: buildBlueprint.coords.map((coord: Dimensions) => new Vector3().fromArray(coord)),
            dimensions: buildDimensions,
            geometry: buildGeometry,
            rotation: new Euler(),
            position: new Vector3(0, 0, 0),
            bounds: {
              min: minBlocksOffset,
              max: maxBlocksOffset,
            },
          };

          const startedAt = new Date();
          // debugger;
          set((state) => {
            state.level = {
              ...state.level,
              id,
              initAnimated,
              // only show when we click showHint
              name: showHint && name,
              hint: showHint && hint,
              blocks,
              locked: [],
              build,
              startedAt,
              finishedAt: undefined,
              loaded: true,
            };

            state.settings.shouldShowHint = showHint && !!name && !!hint;

            return state;
          });
        },
        recalculateLocked: () => {
          get().set((state) => {
            state.level.locked = Object.values(get().level.blocks!).reduce((locked: Coords, block) => {
              locked.push(...block.locked!);
              return locked;
            }, []);
          });
        },
        locked: [],
        reset: () => {
          get().level.init(get().level.id!, true, true);
        },
        finish: () => {
          const finishingDate = new Date();
          const levelId = get().level.id;

          const progress = {
            id: get().level.id as string,
            duration: finishingDate.getTime() - get().level.startedAt!.getTime(),
            session: get().user?.sessions,
            finishedAt: finishingDate,
            ...(get().settings.isHandTracking ? { isHandTracking: get().settings.isHandTracking } : {}),
          };

          get().set((state) => {
            state.level.finishedAt = finishingDate;
            state.user.progress.push(progress);

            // TODO:  remove? Don't think we need this anymore:
            state.levels[levelId!].finishedAt = finishingDate;
            return state;
          });

          const pushProgress = async () => {
            if (get().user.id) {
              const docRef = firestore.collection('players').doc(get().user!.id);
              await docRef.update({
                progress: fb.firestore.FieldValue.arrayUnion(progress),
              });
            }
          };
          pushProgress();
        },
        quit: () => {
          const quitDate = new Date();
          const duration = quitDate.getTime() - get().level.startedAt!.getTime();

          if (duration < 30000) {
            return;
          }

          const progress = {
            id: get().level.id,
            duration: duration,
            session: get().user?.sessions,
            quitAt: quitDate,
          };

          const pushProgress = async () => {
            if (get().user.id) {
              const docRef = firestore.collection('players').doc(get().user!.id);
              await docRef.update({
                progress: fb.firestore.FieldValue.arrayUnion(progress),
              });
            }
          };
          pushProgress();
        },
      },
      set: (fn: (state: State) => State) => {
        set(fn);
      },
    };
  })
);
