export const demo1 = {
  build: {
    coords: [
      [0, 1, 0],
      [1, 1, 0],
      [0, 0, 0],
      [1, 2, 0],
      [0, 2, 0],
      [0, 3, 0],
      [1, 3, 0],
      [1, 4, 0],
      [0, 4, 0],
      [0, 5, 0],
      [1, 5, 0],
      [2, 5, 0],
      [2, 4, 0],
      [3, 4, 0],
      [3, 5, 0],
      [4, 4, 0],
      [4, 5, 0],
      [5, 5, 0],
      [5, 4, 0],
      [5, 3, 0],
      [4, 3, 0],
      [5, 2, 0],
      [4, 2, 0],
      [4, 1, 0],
      [5, 1, 0],
      [3, 1, 0],
      [3, 0, 0],
      [4, 0, 0],
      [5, 0, 0],
      [1, 0, 0],
      [2, 0, 0],
      [2, 1, 0],
    ],
  },
  blocks: [
    {
      coords: [
        [0, 1, 0],
        [1, 1, 0],
        [0, 0, 0],
      ],
    },
    { coords: [[1, 1, 0]] },
    {
      coords: [
        [0, 1, 0],
        [0, 2, 0],
        [1, 2, 0],
        [1, 3, 0],
      ],
    },
    {
      coords: [
        [0, 3, 0],
        [0, 4, 0],
        [1, 4, 0],
        [2, 4, 0],
      ],
    },
    {
      coords: [
        [2, 3, 0],
        [3, 3, 0],
        [3, 4, 0],
        [4, 3, 0],
      ],
    },
    {
      coords: [
        [4, 4, 0],
        [5, 4, 0],
        [5, 3, 0],
        [5, 2, 0],
        [4, 2, 0],
      ],
    },
    {
      coords: [
        [5, 1, 0],
        [4, 1, 0],
        [4, 0, 0],
        [5, 0, 0],
      ],
    },
    {
      coords: [
        [3, 1, 0],
        [3, 0, 0],
        [4, 0, 0],
        [5, 0, 0],
      ],
    },
    {
      coords: [
        [1, 0, 0],
        [2, 0, 0],
        [2, 1, 0],
      ],
    },
  ],
};
