import { Dimensions } from '../../store';
import { demo1 } from './demo/demo1';
import { demo2 } from './demo/demo2';
import { demo3 } from './demo/demo3';
import { demo4 } from './demo/demo4';
import { demo5 } from './demo/demo5';
import { level1 } from './level1';
import { testlevel, testLevel1, testLevel2 } from './testlevel';
import { level3 } from './level3';
import { level4 } from './level4';
import { level5 } from './level5';
import { level6 } from './level6';
import { demo6 } from './demo/demo6';
import { demo7 } from './demo/demo7';
import { demo1x5x6 } from './demo/demo1x5x6';
import { demo3x3x5 } from './demo/demo3x3x5';
import { demosmallpyramid } from './demo/demosmallpyramid';
import { demo2x2x6 } from './demo/demo2x2x6';
import { demob1, demob2, demob3, demob4, demobtutorial } from './demo/demob';

export type ImportedLevel = { build: { coords: Array<Dimensions> }; blocks: [{ coords: Array<Dimensions> }] };

export const demoLevels = [
  ...(process.env.NODE_ENV === 'development' ? ['test1', 'test2'] : []),
  'demobtutorial',
  'demob1',
  'demob3',
  'demob2',
  'demob4',
  'demo1',
  'demo2x2x6',
  'demo0',
  'demo1x5x6',
  'demo2',
  'demo7',
  'demo6',
  'demosmallpyramid',
  'demo3x3x5',
  'demo5',
  'demo3',
];

export const levels: { [key: string]: ImportedLevel } = {
  demo4: (demo4 as unknown) as ImportedLevel, // starter
  demo1x5x6: (demo1x5x6 as unknown) as ImportedLevel,
  demo1: (demo1 as unknown) as ImportedLevel, // donut
  demo2x2x6: (demo2x2x6 as unknown) as ImportedLevel,
  demo0: (level1 as unknown) as ImportedLevel, // 3x3
  demosmallpyramid: (demosmallpyramid as unknown) as ImportedLevel,
  demo6: (demo6 as unknown) as ImportedLevel, // big o
  demo2: (demo2 as unknown) as ImportedLevel, // 3x4
  demo7: (demo7 as unknown) as ImportedLevel, // pyramid
  demo3x3x5: (demo3x3x5 as unknown) as ImportedLevel,
  demo3: (demo3 as unknown) as ImportedLevel, // 4x4
  demo5: (demo5 as unknown) as ImportedLevel, // big stairs,

  demobtutorial,
  demob1,
  demob2,
  demob3,
  demob4,

  test: (testlevel as unknown) as ImportedLevel,

  ...(process.env.NODE_ENV === 'development'
    ? { test1: (testLevel1 as unknown) as ImportedLevel, test2: (testLevel2 as unknown) as ImportedLevel }
    : {}),
  // 1: level2 as unknown as ImportedLevel,
  // 2: level3 as unknown as ImportedLevel,
  // 3: level4 as unknown as ImportedLevel,
  // 4: level5 as unknown as ImportedLevel,
  // 5: level6 as unknown as ImportedLevel,
};
